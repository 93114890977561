const TextStyles = {
    menuText : "font-verdana uppercase tracking-widest text-[24px] text-[#3A2016] md:text-[16px] xl:text-[24px] select-none",
    dropDownText : "font-verdana tracking-widest text-[1vw] text-[#ccc] hover:text-[#fff] select-none px-[1vw]",
    dropDownTextSmall : "font-verdana tracking-widest text-[2vw] text-[#ccc] hover:text-[#fff] select-none px-[1vw]",
    dropDownButton : "nav-link cursor-pointer font-verdana lowercase tracking-widest text-[1.3vw] text-[#3A2016] flex select-none",
    dropDownButtonSmall : "nav-link cursor-pointer font-verdana lowercase tracking-widest text-[2.5vw] text-[#3A2016] flex select-none",
    mainButton : "nav-link cursor-pointer justify-center font-verdana lowercase tracking-widest text-[#3A2016] flex items-center text-[1.3vw] select-none",
    mainButtonSmall : "nav-link cursor-pointer justify-center font-verdana lowercase tracking-widest text-[#3A2016] flex items-center text-[2.5vw] select-none",
    icon : "dropdown-arrow ml-2 mt-1",
    list : "absolute bg-[#515151] space-y-6 py-4 text-center shadow-md  translate-y-2 translate-x-[-4rem]",
    intro : "font-bmf tracking-widest drop-shadow-2xl text-center",
    cardupper : "transition-all duration-200 ease-in-out absolute font-verdana w-full pt-[2vw] tracking-widest justify-self-center text-[2.5vw]",
    cardbottom : "transition-all duration-200 ease-in-out font-verdana text-[1.7vw] w-[40vw] justify-self-center self-end pb-[3vw]",
    cardupperSmall : "transition-all duration-200 ease-in-out absolute font-verdana w-full pt-[4vw] tracking-widest justify-self-center text-[5vw]",
    cardbottomSmall : "transition-all duration-200 ease-in-out font-verdana text-[4vw] w-[90vw] justify-self-center self-end pb-[4vw]",
    contactTitle : "w-full text-center uppercase my-10 text-3xl font-motserrat tracking-widest md:text-2xl xl:text-3xl",
    contactText : "w-full text-left pl-8 font-motserrat tracking-widest md:text-sm xl:text-lg",
    contactIntro: "text-sm mt-16 uppercase whitespace-pre-line tracking-widest drop-shadow-2xl text-center md:text-2xl xl:text-4xl",
    contactIntroButton: "text-sm px-8 py-4 uppercase whitespace-pre-line tracking-widest drop-shadow-2xl text-center md:text-base xl:text-xl",
    contactTitlePage: "font-motserrat mb-8 ml-8 text-2xl text-white whitespace-pre-line tracking-widest drop-shadow-2xl text-left sm:text-3xl md:text-3xl md:mb-12 md:ml-12 lg:text-4xl",
    serviceTitle : 'font-verdana text-[#fff] mt-4 md:mt-6 xl:mt-8 pb-4 md:pb-6 xl:pb-12 text-xl md:text-4xl xl:text-6xl',
    contactTextPage : 'font-motserrat w-full text-left text-base font-motserrat tracking-widest md:text-sm xl:2xl',
    chooseUsText: 'font-verdana text-[#fff] text-center text-sm'

}

export {TextStyles}