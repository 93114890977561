import React from 'react';
import { TextStyles } from "../text";
import { Dropdown } from "./components/dropdownmenu";
import MenuButton from './components/menubutton/MenuButton';
import { MENUTYPE, MenuInterface } from '../../feature/menu/MenuConfig';
import { isMobile } from 'react-device-detect';

export interface MenuListProps {
  array: Array<MenuInterface>;
  onItemClick: () => void; // Додаємо onItemClick у пропси
}

function MenuList(props: MenuListProps) {
  const { array, onItemClick } = props;
  const menuButtonsList: Array<React.JSX.Element> = [];

  for (const link of array) {
    if (link.type === MENUTYPE.BUTTON && link.link) {
      menuButtonsList.push(
        <MenuButton
          key={link.link}
          text={link.text}
          path={link.link}
          style={!isMobile ? TextStyles.mainButton : TextStyles.mainButtonSmall}
          onClick={onItemClick}
        />
      );
    }
    if (link.type === MENUTYPE.DROPDOWN && link.links) {
      menuButtonsList.push(
        <Dropdown
          key={link.text}
          maintext={link.text}
          dropdown={link.links}
          onItemClick={onItemClick}
        />
      );
    }
    if (link.type === MENUTYPE.INDROPBUTTON && link.link) {
      menuButtonsList.push(
        <MenuButton
          key={link.link}
          text={link.text}
          path={link.link}
          style={!isMobile ? TextStyles.dropDownText : TextStyles.dropDownTextSmall}
          onClick={onItemClick}
        />
      );
    }
  }

  return menuButtonsList;
}

export default MenuList;
