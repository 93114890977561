import React, { useState, useEffect } from 'react';
import { Image, ImageStyles } from '../../../../components/image';
import { MenuList } from '../../../../components/menulist';
import { MenuInterface, links } from '../../../menu/MenuConfig';
import useDetectScroll from '@smakss/react-scroll-direction';
import { isMobile } from 'react-device-detect';

export interface MainDropDownProps {
  key?: string;
  links: Array<MenuInterface>;
}

function MainDropDown(props: MainDropDownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { scrollDir } = useDetectScroll();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isMobile && (scrollDir === 'down'|| scrollDir === 'up')) {
      closeMenu();
    }
  }, [scrollDir]);

  // Закриваємо меню при виборі пункту
  const handleItemClick = () => {
    closeMenu();
  };

  return (
    <button className='w-[5vw] h-[3vw] justify-items-end mr-2 self-center select-none'>
      <div className="flex space-x-96 items-center select-none" onClick={toggleMenu}>
        <Image
          path={'/gfx/icons/menu.png'}
          imageStyle={`${ImageStyles.dropdownIcoSmall} ${isOpen ? 'animate-swipe scale-y-[1] md:transform-none' : ''}`}
        />
      </div>

      <div className={`left-0 absolute w-full select-none ${isMobile && 'top-[12vw]'}`}>
        {isOpen && (
          <ul className={`w-full h-full bg-[#E7E8E7] text-center shadow-md select-none ${isMobile ? 'space-y-[2.5vw] py-[2vw]' : 'space-y-[1vw]'}`}>
            <MenuList array={links} onItemClick={handleItemClick} />
          </ul>
        )}
      </div>
    </button>
  );
}

export default MainDropDown;
