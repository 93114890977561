import { useState } from 'react';
import MenuList from '../../MenuList';
import { Text, TextStyles } from '../../../text';
import { Image, ImageStyles } from '../../../image';
import { MenuInterface } from '../../../../feature/menu/MenuConfig';
import { isMobile } from 'react-device-detect';

export interface DropDownProps {
  key?: string;
  maintext: string;
  dropdown: Array<MenuInterface>;
  onItemClick: () => void; // Додаємо onItemClick у пропси
}

function Dropdown(props: DropDownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleItemClick = () => {
    closeMenu();
    props.onItemClick();
  };

  return (
    <div onClick={toggleMenu} className={`${isMobile ? 'w-full' : 'w-max left-auto'}`}>   
      <div className="flex place-content-center items-center">
        <Text text={props.maintext} textStyle={!isMobile ? TextStyles.dropDownButton : TextStyles.dropDownButtonSmall}></Text>
        <Image path={'/gfx/arrow.png'} imageStyle={`${!isMobile ? ImageStyles.dropdownIco : ImageStyles.dropdownIcoArrowSmall} ${isOpen ? 'animate-swipe scale-y-[1] md:transform-none' : ''}`}></Image>
      </div>
      {isOpen && (
        <div>
          <ul className={`bg-[#515151] text-center shadow-md w-full left-0  ${!isMobile ? 'absolute translate-y-[0.5vw] translate-x-[-3.5vw] w-max left-auto space-y-[2vw] py-[1vw]' : 'translate-y-[0.6vw] space-y-[3vw] py-[2vw]'} `} onMouseLeave={closeMenu}>
            <MenuList array={props.dropdown} onItemClick={handleItemClick} />
          </ul>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
