import React from 'react';
import { Image,ImageStyles } from '../../components/image';
import { Menu } from '../menu';
import useStickyScroll from '../../managers/useStickyScroll';
import { MainDropDown } from './components/maindropdown';

import { links } from '../menu/MenuConfig';
//import PhoneDetector from '../../managers/PhoneDetector';
//import { NavigateFunction, useNavigate } from 'react-router-dom';
import NavigationManager from '../../managers/NavigationManager';
import { isMobile } from 'react-device-detect';

let isSticky : boolean
//let isPhone : boolean
//let navitage : NavigateFunction

function Header(): React.JSX.Element {

  isSticky = useStickyScroll();
  //isPhone = PhoneDetector();
  //navitage = useNavigate()

  return (
    <header className={`flex flex-col items-center relative bg-gray-100 h-[12vw] z-10 ${isSticky ? 'justify-start' : 'justify-end'}`}>
      {getHeader()}
    </header>
    
  );
}

function getHeader(){
  
    return ( <div className={`${isMobile ? 'fixed w-full h-min top-0' : 'relative w-full top-auto h-min justify-center items-center'}`}>
        {!isMobile && (
          <div className='w-full justify-center items-center'>
            <div className="flex-grow"></div>
              <div className='flex items-center justify-center mt-auto translate-y-[2vw] z-10'>
                <Image path={'/gfx/logo.png'} imageStyle={`${ImageStyles.header} ${isSticky ? ' invisible' : ''}`}></Image> 
              </div>
              <div className='flex justify-center items-center shadow-lg'>
                <div className='absolute left-0 bottom-0 bg-[#484848] w-full h-[3vw] z-[-1]'></div>
                <Menu />
              </div>
          </div>
        )}

        {isMobile && (
          <div className='flex w-full h-[12vw] justify-end items-center bg-white'>
            <div className='absolute flex w-full h-full justify-end'>
                <button title='facebook' onClick={() => window.open('https://www.facebook.com/profile.php?id=100042066980184')} className='flex self-center justify-center items-center mr-[4vw]'>
                  <Image path={'gfx/icons/Facebook.png'} imageStyle={ImageStyles.socialSmallIcoFacebook} ></Image>
                </button>
                <button title='instagram' onClick={() => window.open('https://instagram.com/studio_doors_tineo?igshid=YTQwZjQ0NmI0OA==')} className='flex self-center justify-center items-center mr-[4vw]'>
                  <Image path={'gfx/icons/Instagram.png'} imageStyle={ImageStyles.socialSmallIcoInstagram} ></Image>
                </button>
                <MainDropDown key={'maindropdown'} links={links}></MainDropDown>
            </div>

            <div className='absolute w-full pointer-events-none'>
              <button title='home' onClick={() => {NavigationManager('/')}} onMouseDown={(e) => e.preventDefault()}  className='ml-4 flex justify-center items-center pointer-events-auto'>
                <Image path={'/gfx/logo.png'} imageStyle={ImageStyles.headerSmall}></Image>
              </button>
            </div>

          </div>
        )}

      </div>
    );
}

export default Header;