import React from 'react';
import useStickyScroll from '../../managers/useStickyScroll';
import { MenuList } from '../../components/menulist';
import { links } from './MenuConfig';

let isSticky;

function Menu() {
  isSticky = useStickyScroll();

  // Обробник для закриття меню при виборі пункту
  const handleItemClick = () => {
    console.log('Menu item clicked');
    // Тут можна додати додаткову логіку, якщо потрібно
  };

  return (
    <nav className={`bg-white border mb-[2vw] shadow-2xl items-center w-[50vw] h-[5vw] ${isSticky ? 'fixed w-full index-y-0 top-0 xl:py-8 md:py-2' : 'py-[1.5vw]'}`}>
      <ul className={`menu flex space-x-[3vw] translate-y-[1vw] justify-center ${isSticky ? 'translate-y-[0] justify-center' : ''}`}>
        <MenuList array={links} onItemClick={handleItemClick} />
      </ul>
    </nav>
  );
}

export default Menu;
